<template>
    <div class="container">
        <p class="p-service">SERVICE</p>
        <van-divider :style="{ color: 'white', borderColor: 'white',margin:'2px 0','font-size':'16px'}">民生泷通平台服务介绍</van-divider>
        <p class="desc">民生泷通新能源城配由民生物流有限公司、重庆泷耀盈通供应链管理有限公司、重庆交运汽车租赁有限公司共同联合发起的新能源城市配送聚合平台。平台依托国有企业背景，以具有竞争力的服务品质，为不同客户提供各种城市配送业务。</p>
    </div>
</template>

<script>
    import {Divider} from 'vant';
    export default {
        name: "ServiceDesc",
        components:{
            [Divider.name]:Divider,
        }
    }
</script>

<style lang="stylus" scoped>
.container
    width 100%
    height auto
    background-color #073060
    color white
    text-align center
    padding 35px 200px
    p
        margin 0
        padding 0
    .p-service
        font-size 3rem
        font-family Myriad Pro Regular, Myriad Pro Regular-Regular
    .desc
        font-size 1rem
        width 50%
        margin 0 auto
@media screen and (min-width: 320px)  and (max-width: 1024px)
    .container
        padding 1rem 0
        .desc
            width 100%
</style>