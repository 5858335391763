<template>
    <div class="container">
        <div class="icon-box">
            <img :src="info.icon">
        </div>
        <div class="info-box">
            <div class="info-title">
                <div class="sn"><span>0{{info.sn}}.</span></div>
                <div class="title"><span>{{info.title}}</span></div>
            </div>
            <div class="info-desc">
                <p>
                    {{info.desc}}
                </p>
            </div>
            <div class="info-btn-box">
                <div class="info-btn">
                    <span>READ MORE</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ServiceIconBtn",
        props:['info']
    }
</script>

<style lang="stylus" scoped>
.container
    width 100%
    height auto
    .icon-box
        width 100%
        height 24rem
        background-color #FAFAFA
        text-align center
        padding 3rem 6rem
        img
            width 100%
            height 100%
    .info-box
        width 100%
        height 30rem
        background-color white
        padding 3rem 3rem
        .info-title
            width 100%
            height auto
            display flex
            border-bottom 2px solid rgba(0,0,0,0.1)
            padding-bottom 1rem
            .sn
                font-family  DIN 1451 Std Engschrift, DIN 1451 Std Engschrift-Engschrift
                font-weight Engschrift
                color  #666666
                letter-spacing  -2px
                font-size 4rem
                opacity: 0.2;

            .title
                font-size 1.5rem
                font-weight  bold
                text-align  left
                color #777777
                padding-top 2rem
                padding-left 1rem
        .info-desc
            color #777777
            font-weight 400
            text-align left
            font-size  1.2rem
            letter-spacing 4px
            height 8rem
        .info-btn-box
            padding 2rem 0px
            .info-btn
                margin 0 auto
                width 50%
                height 3.5rem
                border 1px solid #D4D4D4
                background-color white
                text-align center
                span
                    color  #666666
                    font-weight  400
                    font-size 1rem
                    line-height 3.5rem

</style>