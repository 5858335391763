<template>
    <div>
        <newHeader url="/index"></newHeader>
        <div class="title-box-1">
           BUSINESS INFORMATION
        </div>
        <div class="title-box-2">
            <img src="../assets/images/businessinformation.png" height="31" width="251" class="img-title"/>
            <div class="img-title">
            </div>
        </div>
        <div class="service-container">
            <div class="service-box">
                <div class="content-container">
                    <div class="search-container">
                        <el-select v-model="query.regionItems" @change="clickSearch"
                                   multiple
                                   collapse-tags
                                   clearable placeholder="请选择始发地">
                            <el-option
                                    v-for="item in regionItems"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>

						<el-select v-model="query.regionEndItems" @change="clickSearch"
						           multiple
						           collapse-tags
						           clearable placeholder="请选择目的地">
						    <el-option
						            v-for="item in regionItems"
						            :key="item.id"
						            :label="item.name"
						            :value="item.id">
						    </el-option>
						</el-select>

                        <el-select v-model="query.vehicleItems" @change="clickSearch"
                                   multiple
                                   collapse-tags
                                   clearable placeholder="请选择">
                            <el-option
                                    v-for="item in vehicleItems"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                        <!--						<el-button type="primary" @click="clickSearch">搜索</el-button>-->
                    </div>
                    <!-- v-infinite-scroll="loadCard" -->
                    <div class="projects">
                        <el-card v-for="item in tableData" class="box-card" shadow="hover"
                                 style="width: 384px;height: 220px; margin: 10px 12px;float: left;border-radius: 6px;color: #dbdbdb">
                            <div class="clearfix">
                                <span>{{item.title}}</span>
                                <el-button @click="detail(item.id)" style="float: right; padding: 3px 0;font-size: 18px" icon="el-icon-right" type="text"></el-button>
                            </div>
                            <div class="text item">
                                <el-tag>{{item.regionName}}</el-tag>
                                <el-tag>{{item.vehicleName}}</el-tag>
                                <el-tag>{{item.typeName}}</el-tag>
                                <br><br>
                                <div>
                                    <el-image :src="require('@/assets/images/carSmall_s.png')"/>
                                    <span style="margin-left: 5px;"> {{item.unitName}} </span>
                                </div>
                                <el-row>
                                    <el-col :span="12" style="line-height: 80px"><span style="color: orangered;font-size: 30px;font-weight :600">{{item.price}}</span>元/车</el-col>
                                    <el-col :span="12">
                                        <el-image style="float: right;" :src="require('@/assets/images/carBig_s.png')"/>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-card>
                    </div>
                    <div class="pagination">
                        <el-pagination background layout="prev, pager, next"
                                       :current-page="query.pageIndex" :page-size="query.pageRows"
                                       :total="pageTotal" @current-change="handlePageChange" prev-click="pageChange(1)" next-click="pageChange(2)"></el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!--        <ServiceDesc></ServiceDesc>-->
        <!--        <div class="service-icon-btn-container">-->
        <!--            <div class="service-icon-btn-container-box">-->
        <!--                <ServiceIconBtn class="service-icon-btn" v-for="item in services" :key="item.sn" :info="item"></ServiceIconBtn>-->
        <!--            </div>-->
        <!--        </div>-->
        <Buttom></Buttom>
<!--        <div class="contact-us" v-if="isShowButton">-->
<!--            <ContactUsShow v-on:isShow="isShow"></ContactUsShow>-->
<!--        </div>-->

        <el-dialog :visible.sync="detailDialog" width="80%">
            <div style="padding: 0 3%;">
                <el-row class="userInfo_list">
                    <div class="grid-content">
                        <div class="lost-title">{{project.title}}</div>
                        <br>
                    </div>
                </el-row>

                <div style="display: flex;margin: 0 auto;">
                    <el-descriptions title="装货信息" :column="1" border style="width: 45%;float: left;" size="medium">
                        <el-descriptions-item label="始发地" >{{project.regionName}}</el-descriptions-item>
                        <el-descriptions-item label="装货地址" >{{project.loadAddress}}</el-descriptions-item>
                        <el-descriptions-item label="联系人" >{{project.projectContactName}}</el-descriptions-item>
                        <el-descriptions-item label="联系电话" >{{project.projectContactPhone}}}</el-descriptions-item>
                        <el-descriptions-item label="业务持续时间" >{{project.projectTimeRange}}</el-descriptions-item>
                    </el-descriptions>

                    <img src="../assets/images/jiantou.jpg" width="200px" height="30px" style="margin: 110px auto;"/>

                    <el-descriptions title="卸货信息" :column="1" border style="width: 45%;float: right;" size="medium">
                        <el-descriptions-item label="目的地">{{project.regionEndName}}</el-descriptions-item>
                        <el-descriptions-item label="卸货地址">{{project.unloadAddress}}</el-descriptions-item>
                        <el-descriptions-item label="装卸货方式" >
                            {{project.loadStatus==1?'司机装卸':project.loadStatus==2?'客户提供装卸':''}}
                        </el-descriptions-item>
                        <el-descriptions-item label="业务截止时间" >{{project.projectDeadline}}</el-descriptions-item>
                        <el-descriptions-item label="业务类型" >
							<span v-if="project.projectType==1">城配业务</span>
							<span v-if="project.projectType==2">支线业务</span>
							<span v-if="project.projectType==3">干线业务</span>
						</el-descriptions-item>
                    </el-descriptions>
                </div>

                <div style="margin-top: 20px;">
                    <el-descriptions title="货物信息" direction="vertical" :column="4" border size="medium">
                        <el-descriptions-item label="货物类型" >{{project.typeName}}</el-descriptions-item>
                        <el-descriptions-item label="车辆类型" >{{project.vehicleName}}</el-descriptions-item>
                        <el-descriptions-item label="运费">{{project.price}}</el-descriptions-item>
                        <el-descriptions-item label="运输有无特别要求" >{{project.remark}}</el-descriptions-item>
                    </el-descriptions>
                </div>
                <div style="width: 100%;border: 1px solid #d8d8d8;height: 300px;margin-top: 10px;">
                    <div style="margin-top: 1%;margin-left: 1%;">详细描述</div>
                    <el-input type="textarea" v-model="project.detailDesc" :rows="12" resize="none" readonly="readonly"
                              style="margin-top: 1%;margin-left: 1%;width: 98%;"></el-input>
                </div>

                <div style="margin-top: 10px;text-align: center;">
                    <el-button @click="apply(project.id,project.userId,project.inputType)" type="primary" style="width: 108px;background-color: #073060;border-color: #073060;">申
                        请
                    </el-button>
                </div>

            </div>
        </el-dialog>

    </div>
</template>

<script>
    import newHeader from '@/components/common/newHeader'
    import Swipper from "@/components/common/index/Swipper";
    import ServiceDesc from "@/components/common/index/ServiceDesc";
    import ServiceIconBtn from "@/components/common/index/ServiceIconBtn";
    import icon1 from "@/assets/images/btn1.png"
    import icon2 from "@/assets/images/btn2.png"
    import icon3 from "@/assets/images/btn3.png"
    import icon4 from "@/assets/images/btn4.png"
    import icon5 from "@/assets/images/btn5.png"
    import icon6 from "@/assets/images/btn6.png"
    import Buttom from "@/components/common/Buttom";
    // import ContactUsShow from "@/components/common/index/ContactUsShow";

    export default {
        name: "Index",
        data: function () {
            return {
                services: [
                    {
                        sn: 1,
                        title: '金融服务',
                        desc: '平台能对长期应收款提供垫资服务对汽车供应链也有相应的金融服务，成本低资金稳定',
                        icon: icon1
                    }, {
                        sn: 2,
                        title: '项目、资源共享服务',
                        desc: '加入平台后，可共享项目资质，促进项目签约及投标成果',
                        icon: icon2
                    }, {
                        sn: 3,
                        title: '团队运营服务',
                        desc: '有成熟的经营管理团队，对经营中的问题能快速解决，能支持企业在行业中稳定发展',
                        icon: icon3
                    }, {
                        sn: 4,
                        title: '仓配、运力服务',
                        desc: '有仓配一体化物流解决方案，对 运力拓展有渠道共享，提供项目制一对一服务',
                        icon: icon4
                    }, {
                        sn: 5,
                        title: '车源、保险等服务',
                        desc: '利用平台背景，提高议价权，与个大主机商、保险公司签订大客户协议，提供优质车源及享有优惠保险政策',
                        icon: icon5
                    }, {
                        sn: 6,
                        title: '税务服务',
                        desc: '已成立合规合法的税收红利政策公司，可对增值税缴纳部分进行优惠税务返还',
                        icon: icon6
                    },
                ],
                isShowButton: true,
                query: {
                    regionItems: [],
					regionEndItems: [],
                    vehicleItems: [],
                    pageIndex: 1,
                    pageRows: 9,
                    isValid: true
                },
                tableData: [],
                pageTotal: 0,
                regionItems: [],
                vehicleItems: [],
                typeItems: [],
                sortTypes: [
                    "发布时间",
                ],
                detailDialog: false,
                project: {},

                title: '流程与服务',
                content: 'Strategic planning is to allow you to accept a  better service',

                radio: '',
            }
        },
        components: {
            newHeader,
            Buttom,
            ServiceIconBtn,
            ServiceDesc,
            Swipper,
        },
        created() {
            this.clickSearch();
            this.initRegionItem();
            this.initVehicleItem();
            this.initTypeItem();
        },
        methods: {
            isShow: function (e) {
                this.isShowButton = e;
            },
            initRegionItem: function () {
                this.HTTP.get('/base/initRegionItem').then(res => {
                    if (res.data.code == 1) {
                        this.regionItems = res.data.rows;
                    }
                }).catch(err => {
                    this.$message.error(err);
                })
            },
            initVehicleItem: function () {
                this.HTTP.get('/base/initVehicleItem').then(res => {
                    if (res.data.code == 1) {
                        this.vehicleItems = res.data.rows;
                    }
                }).catch(err => {
                    this.$message.error(err);
                })
            },
            initTypeItem: function () {
                this.HTTP.get('/base/initTypeItem').then(res => {
                    if (res.data.code == 1) {
                        this.typeItems = res.data.rows;
                    }
                }).catch(err => {
                    this.$message.error(err);
                })
            },
            clickSearch: function () {
                this.query.pageIndex = 1;
                this.handleSearch();
            },
            handlePageChange: function (e) {
                this.query.pageIndex = e
                this.handleSearch()
            },
            handleSearch: function () {
                this.HTTP.post('/project/list', this.query).then(res => {
                    if (res.data.code == 1) {
                        this.tableData = res.data.rows
                        this.pageTotal = res.data.counts
                    } else {
                        this.$message.error(res.data.message);
                    }
                }).catch(err => {
                    this.$message.error(err);
                })
            },
            checkUserInfo: function () {
                let userInfo = sessionStorage.getItem("userinfo");
                if (userInfo == null) {
                    this.$confirm('您还未登陆, 是否立即跳转到登陆界面?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'info'
                    }).then(() => {
                        this.$router.push({name: 'Login'});
                    }).catch(() => {
                    })
                    return false;
                }

                let json = JSON.parse(userInfo);
                if (!json.isCorrect) {
                    this.$confirm('您的公司信息尚未完善, 是否立即前往完善资料信息?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'info'
                    }).then(() => {
                        this.$router.push({name: 'userPolish'});
                    }).catch(() => {
                    })
                    return false;
                } else {
                    return true;
                }
            },
            detail: function (id) {
                if (this.checkUserInfo()) {
                    //调用详情接口
                    this.HTTP.get('/project/detail?id=' + id).then(res => {
                        if (res.data.code == 1) {
                            this.detailDialog = true;
                            this.project = res.data.data;
                        } else {
                            this.$message.error(res.data.message);
                        }
                    }).catch(err => {
                        this.$message.error(err);
                    })
                }
            },
            apply: function (id, inputId, inputType) {
                this.$confirm('提交申请后需等待平台分配，是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let req = {
                        type: 1,
                        id: id,
                        inputId: inputId,
                        inputType: inputType
                    }
                    this.HTTP.post('/apply/submitApply', req).then(res => {
                        if (res.data.code == 1) {
                            this.$alert('项目申请提交成功，请等待平台分配或前往个人中心查看', '提示', {confirmButtonText: '确定', type: 'success'});
                        } else {
                            this.$message.error(res.data.message);
                        }
                    }).catch(err => {
                        this.$message.error(err);
                    })
                })
            },


        }
    }
</script>

<style lang="stylus" scoped>
    >>>.el-pager li {
		width: 24px !important;
		height: 24px !important;
		border-radius: 12px !important;
		min-width 24px !important;
    }
	>>>.btn-next{
		width: 24px !important;
		height: 24px !important;
		border-radius: 12px !important;
		min-width 24px !important;
	}

    .title
        text-align -webkit-center
        margin 85px 0 48px 0
	>>> .btn-prev{
		width: 24px !important;
		height: 24px !important;
		border-radius: 12px !important;
		min-width 24px !important;
	}


	.service-container
        width 100%
        height auto
        padding-bottom 20px

        .service-box
            /*width 1000px*/
            display flex
            margin 0 auto
            flex-direction row
            justify-content space-around
            align-content center

            .service
                width 30%


    .content-container
        width 123rem
        height 83rem
        margin 0 auto
        margin-bottom 20px
        box-sizing border-box
        background-color #e2f4ff

        h2
            text-align center

        .search-container
            box-sizing border-box
            background-color lightgrey
            height 5rem
            padding 1rem
            display flex
            justify-content space-around

    .service-icon-btn-container
        width 100%
        height auto

        .service-icon-btn-container-box
            padding 2rem 0
            margin 0 auto
            width 100rem
            height auto
            display flex
            flex-direction row
            flex-wrap wrap
            justify-content space-around
            align-content center

            .service-icon-btn
                width 30rem

    .contact-us
        width 180px
        height auto
        position fixed
        top 35%
        right 20px
        z-index 99999

    .el-pagination {
        clear: both;
        text-align: center;
        padding: 10px;
        border-radius 12px;
    }

    .clearfix {
        font-family Microsoft YaHei
        font-size 18px
        color #555555
        font-weight 600
        margin-bottom 15px
    }

    >>> .el-select .el-input .el-select__caret::before {
        /*content: "\e78f"*/
        content: "";
        background: url(../assets/images/caret_bottom.png) center center no-repeat;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    >>> .el-select {
        width 384px
        height 36px
    }

    >>> .el-tag {
        font-family Microsoft YaHei
        font-size 12px
        color #dbdbdb
        height 22px
        margin-right 5px
        background-color #ffffff
        border-color #dbdbdb
    }

    .el-card .el-image {
        opacity 0.5
    }

    >>> .el-card :hover {
        color #666666

        .clearfix {
            color #1a4fcd
        }

        .el-tag {
            background-color #4d90f4
            color #ffffff
        }

        .el-image {
            opacity 1
        }
    }


    @media screen and (min-width: 320px)  and (max-width: 1400px)
        .service-container
            .service-box
                width 100%
                flex-wrap wrap

                .service
                    width 80%
                    margin-bottom 1rem

        .service-icon-btn-container
            .service-icon-btn-container-box
                width 100%

                .service-icon-btn
                    width 40%

    @media screen and (min-width: 320px)  and (max-width: 768px)
        .service-icon-btn-container
            .service-icon-btn-container-box
                width 100%

                .service-icon-btn
                    width 80%

</style>

<style scoped>

    .userInfo_list .grid-content {
        line-height: 20px;
    }

    .grid-content {
        margin: 10px 0;
        display: flex;
        flex-direction: row;
        align-items: center !important;
    }

    .userInfo_list .lost-title {
        font-weight: 400;
        color: #1f2f3d;
        font-size: 25px;
        display: flex;
        margin: 0 auto;

    }

    .my-label {
        background: #00FFFF !important;
        text-align: center !important;
        color: black !important;
    }

    .my-label-2 {
        text-align: center !important;
        color: black !important;
    }

    .my-content {
        text-align: center !important;
        color: black !important;
    }
    .title-box-1 {
        height: 200px;
        font-size: 52px;
        color: #f5f7fc;
        line-height: 200px;
        z-index: -999;
        position: absolute;
        width: 100%;
        letter-spacing: 5px;
        text-align: center;
    }

    .title-box-2 {
        padding: 100px 0;
        text-align: center;
    }

    .img-title:after {
        content: '';
        width: 50px;
        height: 1px;
        display: block;
        margin: 0 auto;
        border-bottom: 3px solid #1a4fcd;
    }

</style>
